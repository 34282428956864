import React, { useEffect, useRef } from 'react';
import './Tabs.css';

const Tabs = ({ activeTab, setActiveTab }) => {
  const navRef = useRef(null);
  const sliderRef = useRef(null);

  const tabsData = [
    { key: 'bakery', icon: `bakery` },
    { key: 'menu', icon: `menu` },
    { key: 'friends', icon: `friends` },
    { key: 'gifts', icon: `gifts` },
    { key: 'shop', icon: `shop` },
  ];

  useEffect(() => {
    updateSliderPosition();
  }, [activeTab]);

  const updateSliderPosition = () => {
    const activeElement = navRef.current.querySelector('.active');
    if (activeElement && sliderRef.current) {
       //sliderRef.current.style.width = `${activeElement.offsetWidth + 20}px`;
       sliderRef.current.style.left = `${activeElement.offsetLeft+(activeElement.offsetWidth /2) -30}px`;
    }
  };

  return (
    <div className="navbar" ref={navRef}>
      {tabsData.map((item) => (
        <div
          key={item.key}
          className={`nav-item ${activeTab === item.key ? 'active' : ''}`}
          onClick={() => setActiveTab(item.key)}
        >
        <div className='nav-content'>
          <div className="icon-wrapper">
            <img src={`images/tabs/${item.icon}.svg`} alt={`${item.key} icon`} />
          </div>
          {activeTab === item.key && <span className="nav-text">{item.key.charAt(0).toUpperCase() + item.key.slice(1)}</span>}
        </div>
        </div>
      ))}
      <div className="indicator" ref={sliderRef}></div>

    </div>
  );
};

export default Tabs;
