// components/ConfirmationDialog.js
import React from 'react';
import './CollectionActionDialog.css';
import utilities from '../../services/utilities.js';

const CollectionActionDialog = ({ title, message, popData, onConfirm, onCancel }) => {
  return (
    <div className="overlay">
     <div className="food-item">
      <div className="close-button" onClick={onCancel}>×</div>
      <h3>{popData.name}</h3>
      <div className="card-icon"> 
      <img src={`images/booster/${popData.image}.png`} alt={popData.name} width={100} height={100} />
      </div>
      <p>{popData.description}</p>
      {/* <div className="ratings">
      <img src={`images/coin.png`} alt={popData.name} width={12} height={12} /> 
      <span>+{Math.round( popData.ratePerHour * (popData.rateMultiplier-1) )}/hr</span>
      </div> */}
      {popData.levelUpCost?(
      <div className="price">
        <img src={`images/coin.png`} alt={popData.name} width={12} height={12} />  
        <span>{popData.levelUpCost.toLocaleString()}</span>
      </div>) : 'Free'
      }
      <button className="buy-button" onClick={onConfirm}>Buy</button>
    </div>
   
    </div>
  );
};

export default CollectionActionDialog;
