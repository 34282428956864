import React, { useState, useEffect, useRef } from 'react';
import apiService from '../../services/apiService.js';
import './MiningCards.css';
import ErrorMessage from '../common/ErrorMessage.js';
import ActionDialog from './ActionDialog.js';
import DogFeedPopUp from '../doggo/DogFeedPopUp.js';
import DogAtePopUp from '../doggo/DogAtePopUp.js';
import utilities from '../../services/utilities.js';
import Card from './Card.js';

const MiningCards = ({ onCardUpgrade, backToBakery }) => {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [upgradeError, setUpgradeError] = useState('');
 
  const [showDoggoPopup, setShowDoggoPopup] = useState(false);
  const [doggoMessage, setDoggoMessage] = useState('Doggo is hungry');
  
  const [doggoType, setDoggoType] = useState('hungry');
  const [doggoReward, setDoggoReward] = useState(0);
  const [doggoRewardRate, setDoggoRewardRate] = useState(0);

  const [showDoggoAtePopup, setShowDoggoAtePopup] = useState(false);
  const [doggoAteReward, setDoggoAteReward] = useState(0);
  const [doggoAteRewardRate, setDoggoAteRewardRate] = useState(0);

  const [dogFeedCounter, setDogFeedCounter] = useState(0);
  const [dogAteCounter, setDogAteCounter] = useState(0);



  const categories = [
    { key: '1', name: 'Cookie' },
    { key: '2', name: 'Drinks' },
    { key: '3', name: 'Ice Cream' },
    { key: '4', name: 'Quick Bites' },
  ];
  const [currentCategory, setCurrentCategory] = useState(categories[0].key);
  const [sliderStyle, setSliderStyle] = useState({});
  const tabsRef = useRef([]);

  useEffect(() => {
    fetchCards();
  }, [currentCategory]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateSliderPosition();
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [currentCategory]);

  useEffect(() => {
    updateSliderPosition();
  }, [currentCategory]);



  const fetchCards = async () => {
    try {
      const response = await apiService.get('/user-cards?category=1');
      const cardData = response.data.data || [];
      const filteredCards = cardData.filter(card => card.subCategory.toLowerCase() === currentCategory.toLowerCase()).sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));
      setCards(Array.isArray(filteredCards) ? filteredCards : []);
    } catch (error) {
      console.error('Error fetching mining cards:', error);
    }
  };

  const openDoggoPopup = () => {
    setDogFeedCounter((prev) => (prev + 1) % 5);
    return dogFeedCounter === 4;
  };

  const randomDoggoReward = () => {
    const random = Math.random();
    let rate = 0;
    if (random <= 0.5) {
      rate = 2;
    }
    setDoggoRewardRate(rate);
    setDoggoReward(Math.round(rate * selectedCard.ratePerHour  * selectedCard.rateMultiplier));
  };

  const openDoggoAtePopup = () => {
    setDogAteCounter((prev) => (prev + 1) % 7);
    if (dogAteCounter === 6) {
      const rewardRates = [5, 10, 15];
      const randomRate = rewardRates[Math.floor(Math.random() * rewardRates.length)];
      setDoggoAteRewardRate(randomRate);
      setDoggoRewardRate(1 + (randomRate/100));
      setDoggoAteReward(Math.round((randomRate/100) * selectedCard.ratePerHour * selectedCard.rateMultiplier ));
      return true;
    }
    return false;
  };

  const openDoggoAction = () => {
    if (openDoggoPopup()) {
      randomDoggoReward();
      setShowDoggoPopup(true);
      return true;
    }
    if (openDoggoAtePopup()) {
      setShowDoggoAtePopup(true);
      return true;
    }
    return false;
  };
  
  const handleCardClick = (card) => {
    if(!card.isLocked){
      setSelectedCard(card);
    }
  };

  const upgradeCard = async (isZeroEarn, isFree) => {
    //if (!selectedCard && showDoggoPopup) return;
   
    try {
      const response = await apiService.post('/upgrade-user-card', 
        { 
          cardID: selectedCard._id, 
          rewardMultiplier: doggoRewardRate, 
          isFree : isFree ,
          isZeroEarn : isZeroEarn
        });
      if (response.data.status === 1) {
        fetchCards();
        onCardUpgrade?.();
      } else {
        console.error('Failed to upgrade card:', response.data.message);
        setUpgradeError(response.data.message || 'Failed to upgrade the card');
      }
    } catch (error) {
      console.error('Error upgrading card:', error);
      setUpgradeError('Error upgrading the card. Please try again later.');
    } finally {
      setSelectedCard(null);
    }
  
  };

  const handleConfirm = () => {
    if(!openDoggoAction()){
      upgradeCard();
    }
  };

  // useEffect(() => {
  //   if (selectedCard && !showDoggoPopup) {
  //     upgradeCard();
  //   }
  // }, [showDoggoPopup]);

  const handleCancel = () => {
    setSelectedCard(null);
    setUpgradeError('');
  };

  const resetDoggoFeed = () => {
    setShowDoggoPopup(false);
    setDoggoType('hungry');
    setDoggoMessage('Doggo is hungry');
    setDoggoRewardRate(0);
    setDoggoReward(0);
  };

  const handleFeedDoggo = async () => {
    if (doggoType === 'hungry') {
      setDoggoMessage(doggoReward > 0 ? 'You are EPIC!!!' : 'Ha Ha...');
      setDoggoType(doggoReward > 0 ? 'happy' : 'haha');
    } else {
      resetDoggoFeed();
    }
  };

  const handleNopeDoggo = () => {
    if (doggoType === 'hungry') {
      setDoggoRewardRate(0);
      setDoggoReward(0);
      setDoggoMessage('Really...');
      setDoggoType('really');
    } else {
      resetDoggoFeed();
    }
  };

  const handleCollect = async () => {
    console.log("upgrade card");
    //if (doggoType === 'happy' && doggoReward > 0) {
      await upgradeCard(doggoType === 'haha');
      onCardUpgrade?.();
    //}
    resetDoggoFeed();
  };

  const handleDoggoAteCollect = async () => {
    await upgradeCard();
    onCardUpgrade?.();
    setShowDoggoAtePopup(false);
    setDoggoAteRewardRate(0);
    setDoggoAteReward(0);
    setDoggoRewardRate(0);
  };

  useEffect(() => {
    updateSliderPosition();
  }, [currentCategory]);

  const updateSliderPosition = () => {
    const activeTabIndex = categories.findIndex(cat => cat.key === currentCategory);
    const activeTab = tabsRef.current[activeTabIndex];
    if (activeTab) {
      setSliderStyle({
        width: `${activeTab.offsetWidth}px`,
        left: `${activeTab.offsetLeft}px`,
      });
    }
  };

  const handleCategoryChange = (categoryKey) => {
    setCurrentCategory(categoryKey);
  };

  return (
    <div> 
    <div className="header">
    <button className="menu-icon" onClick={backToBakery}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.0001 12C21.0001 12.552 20.5531 13 20.0001 13H6.41413L8.7071 15.293C9.0981 15.684 9.0981 16.3161 8.7071 16.7071C8.5121 16.9021 8.25607 17 8.00007 17C7.74407 17 7.48804 16.9021 7.29304 16.7071L3.29401 12.708C3.20101 12.615 3.12797 12.5051 3.07697 12.3821C2.97597 12.1381 2.97597 11.862 3.07697 11.618C3.12797 11.495 3.20101 11.385 3.29401 11.292L7.29304 7.29301C7.68403 6.90201 8.3161 6.90201 8.7071 7.29301C9.0981 7.68401 9.0981 8.31607 8.7071 8.70707L6.41413 11H20.0001C20.5531 11 21.0001 11.448 21.0001 12Z" fill="#745E50"/>
      </svg>

    </button>
    {/* <div className="title">Fufu's Kitchen</div> */}
   
  </div>
    <div className="mining-cards-container">
       <div className="category-tabs">
        <div className="slider" style={sliderStyle}></div>
        {categories.map((category, index) => (
          <button
            key={category.key}
            ref={el => tabsRef.current[index] = el}
            className={`category-tab ${currentCategory === category.key ? 'active' : ''}`}
            onClick={() => setCurrentCategory(category.key)}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="card-list">
        {upgradeError && (
          <ErrorMessage message={upgradeError} onClose={() => setUpgradeError('')} />
        )}
        {cards.length === 0 ? (
          <p>No cards available for this category</p>
        ) : (
          cards.map((card, index) => (
            <Card index={index} card={card} handleCardClick={ handleCardClick}/>
            
          ))
        )}
        {selectedCard && (
          <ActionDialog
            title="Confirm Selection"
            message={`Are you sure you want to upgrade the ${selectedCard.name} card?`}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            card ={selectedCard}
          />
        )}
        {showDoggoPopup && (
        <DogFeedPopUp onFeed={handleFeedDoggo} 
                      onNope={handleNopeDoggo} 
                      onCollect={handleCollect} 
                      message={doggoMessage} 
                      type = {doggoType} 
                      amount ={doggoReward}/>
      )}
      {(!showDoggoPopup && showDoggoAtePopup) && (
        <DogAtePopUp 
          onCollect={handleDoggoAteCollect} 
          rate={doggoAteRewardRate} 
          amount={doggoAteReward}
        />
      )}
      </div>
    </div>
    </div>
  );
};

export default MiningCards;