// components/TileButton.js
import React from 'react';
import Coins from './Coins';

import './Boosters.css';
import utilities from '../../services/utilities';
import BoosterItem from './BoosterItem';


const Boosters = ({ cards, handleCardClick, fullTankTimeLeft, formatTime, balance, toggleBoosters }) => {
  return (
    <div>
    <div className="header">
      <button className="menu-icon" onClick={toggleBoosters}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.0001 12C21.0001 12.552 20.5531 13 20.0001 13H6.41413L8.7071 15.293C9.0981 15.684 9.0981 16.3161 8.7071 16.7071C8.5121 16.9021 8.25607 17 8.00007 17C7.74407 17 7.48804 16.9021 7.29304 16.7071L3.29401 12.708C3.20101 12.615 3.12797 12.5051 3.07697 12.3821C2.97597 12.1381 2.97597 11.862 3.07697 11.618C3.12797 11.495 3.20101 11.385 3.29401 11.292L7.29304 7.29301C7.68403 6.90201 8.3161 6.90201 8.7071 7.29301C9.0981 7.68401 9.0981 8.31607 8.7071 8.70707L6.41413 11H20.0001C20.5531 11 21.0001 11.448 21.0001 12Z" fill="#745E50"/>
        </svg>

      </button>
      {/* <div className="title">Fufu's Kitchen</div> */}
     
    </div>

         <Coins balance={balance} />
         <div className='booster-img-container'>
            <img src='images/booster/booster_cup.png' width={135} height={122} />
         </div>
         <h4>Boosters</h4>
    <div className="tile-button">
      {cards.length === 0 ? (
        <div>No cards available</div>
      ) : (
        cards.map((card, index) => (
          <BoosterItem index = {index} booster = {card} fullTankTimeLeft={fullTankTimeLeft} formatTime={formatTime} handleCardClick={handleCardClick} />
        ))
        
      )}
    </div>
    </div>
  );
};

export default Boosters;
