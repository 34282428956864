import React, { useState, useEffect } from 'react';
import './UserLevel.css';

const chefPositions = [
  { title: 'Cook', image: 'level_1.png', coins: '20k/100k', progress: 20 },
  { title: 'Prep Cook', image: 'level_2.png', coins: '40k/100k', progress: 40 },
  { title: 'Junior Chef', image: 'level_3.png', coins: '60k/100k', progress: 60 },
  { title: 'Swing Chef', image: 'level_4.png', coins: '74k/100k', progress: 74 },
  { title: 'Roast Chef', image: 'level_5.png', coins: '90k/100k', progress: 90 },
];

const UserLevel = ({ backToBakery }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  let touchStartX = 0;
  let touchEndX = 0;

  const handleNext = () => {
    if (currentPosition < chefPositions.length - 1) {
      setCurrentPosition(currentPosition + 1);
    }
  };

  const handlePrev = () => {
    if (currentPosition > 0) {
      setCurrentPosition(currentPosition - 1);
    }
  };

  const handleTouchStart = (e) => {
    touchStartX = e.changedTouches[0].screenX;
  };

  const handleTouchEnd = (e) => {
    touchEndX = e.changedTouches[0].screenX;
    handleGesture();
  };

  const handleGesture = () => {
    if (touchStartX - touchEndX > 50) {
      handleNext();
    }
    if (touchEndX - touchStartX > 50) {
      handlePrev();
    }
  };

  useEffect(() => {
    const slider = document.querySelector('.chef-slider');

    slider.addEventListener('touchstart', handleTouchStart);
    slider.addEventListener('touchend', handleTouchEnd);

    return () => {
      slider.removeEventListener('touchstart', handleTouchStart);
      slider.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentPosition]);

  return (
    <div className="chef-slider">
      <main>
        <button className="menu-icon" onClick={backToBakery}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0001 12C21.0001 12.552 20.5531 13 20.0001 13H6.41413L8.7071 15.293C9.0981 15.684 9.0981 16.3161 8.7071 16.7071C8.5121 16.9021 8.25607 17 8.00007 17C7.74407 17 7.48804 16.9021 7.29304 16.7071L3.29401 12.708C3.20101 12.615 3.12797 12.5051 3.07697 12.3821C2.97597 12.1381 2.97597 11.862 3.07697 11.618C3.12797 11.495 3.20101 11.385 3.29401 11.292L7.29304 7.29301C7.68403 6.90201 8.3161 6.90201 8.7071 7.29301C9.0981 7.68401 9.0981 8.31607 8.7071 8.70707L6.41413 11H20.0001C20.5531 11 21.0001 11.448 21.0001 12Z" fill="#745E50" />
          </svg>
        </button>
        <h2>{chefPositions[currentPosition].title}</h2>
        <p>Your number of coins determines your position in the kitchen</p>
        <img
          src={`images/user_level/${chefPositions[currentPosition].image}`}
          alt={chefPositions[currentPosition].title}
          className="chef-image"
        />
        <p className="user-level-coins">{chefPositions[currentPosition].coins}</p>

        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: chefPositions[currentPosition].progress + '%' }}
          ></div>
        </div>
      </main>
      <nav>
        {currentPosition > 0 && (
          <button onClick={handlePrev} className="nav-button prev">&lt;</button>
        )}
        {currentPosition < chefPositions.length - 1 && (
          <button onClick={handleNext} className="nav-button next">&gt;</button>
        )}
      </nav>
    </div>
  );
};

export default UserLevel;
