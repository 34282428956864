import './DogFeedPopUp.css';
import utilities from '../../services/utilities';

const DogFeedPopUp = ({ onFeed, onNope, message, type, amount, onCollect }) => {
    return (
      <div className="doggo-popup">
        <div className="doggo-popup-content">
          <h4>{message}</h4>

          <img src={`/images/doggo/${type}.gif`} height={100} width={190} alt={type} />
          {((type == "happy" || type == "haha") && amount != null) && (<div><img src={`images/coin.png`} alt={'coin'} width={12} height={12} /> {utilities.formatNumber (amount)}/hr</div>)}
          <div className="doggo-popup-buttons">
          {type == "hungry" ?
           ( <><button onClick={onFeed}>Feed</button>
            <button onClick={onNope}>Nope</button></>) : ''
          }
          {type == "really" || type == "happy" || type == "haha" ?
           ( <>
            <button onClick={onCollect}>Collect</button></>) : ''
          }
          </div>
        </div>
      </div>
    );
  };

  export default DogFeedPopUp;