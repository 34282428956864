// components/Coins.js
import React from 'react';
import './Coins.css';

const Coins = ({ balance }) => {
  return (
    <div className="coins">
      <img src="/images/coin.png" alt="Coin" height={42} width={42} />
      <div className="coins-text" id="coin-text">{balance.toLocaleString('en-US')}</div>
    </div>
  );
};

export default Coins;
