// loginService.js
import apiService from './apiService';

const userService = {
    

   setFriend: async (param) => {
        try {
          const response = await apiService.post('/user-friend', param);
          
          return response.data;
        } catch (error) {
          console.error('Error getting token:', error);
          throw error;
        }
      },
};

export default userService;
