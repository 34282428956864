// components/Stats.js
import React from 'react';
import './Stats.css';
import utilities from '../../services/utilities';

const Stats = ({ hourlyProfitRate, ratePerClick, setActiveTab, toggleBoosters }) => {
  return (
    <div className="stats">
      <div className="stat-box" onClick={toggleBoosters}>
      <img className='left-top' src='images/booster/booster.svg' height={20} width={20}/>
        <div className='stat-box-header'>Earn Per Tap</div>
        <div className='stat-box-content'><img src="/images/coin.png" alt="Coin" height={18} width={18} /> <span>+{ratePerClick}</span></div>
      </div>
      <div className="stat-box" onClick={()=> setActiveTab("user-level")}>
        <div className='stat-box-header'>Level</div>
        <div className='stat-box-content'><img src="/images/star.png" alt="Star" height={18} width={18} /> <span>1/10</span></div>
      </div>
      <div className="stat-box">
        <div className='stat-box-header'>Income</div>
        <div className='stat-box-content'><img src="/images/coin.png" alt="Coin" height={18} width={18} /><span>{utilities.formatNumber (hourlyProfitRate)}/hr</span></div>
      </div>
    </div>
  );
};

export default Stats;
