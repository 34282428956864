import React, { useState, useEffect, useCallback }from 'react';
import './Tasks.css';
import DailyReward from '../gifts/DailyReward';


const Tasks = () => {
  const [dailyReward, setDailyReward] = useState(false);

  const tasks = [
    {key:'daily-reward', icon: 'calendar', title: 'Daily Reward', coins: '13,397,590' },
    {key:'telegram', icon: 'telegram', title: 'Join Our Tele', coins: '13,397,590' },
    {key:'friend', icon: 'friend', title: 'Invite A Friend', coins: '13,397,590' },
    {key:'youtube', icon: 'youtube', title: 'Watch A Video', coins: '13,397,590' }
  ];
  const toggleDailyReward = () => setDailyReward(prev => !prev);

  const taskOnClick = (key) =>{
    if(key == 'daily-reward'){
      toggleDailyReward();
    }
  };

  return (
    <div className="tasks-container">
      <div className="header">
        <button className="back-button">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0001 12C21.0001 12.552 20.5531 13 20.0001 13H6.41413L8.7071 15.293C9.0981 15.684 9.0981 16.3161 8.7071 16.7071C8.5121 16.9021 8.25607 17 8.00007 17C7.74407 17 7.48804 16.9021 7.29304 16.7071L3.29401 12.708C3.20101 12.615 3.12797 12.5051 3.07697 12.3821C2.97597 12.1381 2.97597 11.862 3.07697 11.618C3.12797 11.495 3.20101 11.385 3.29401 11.292L7.29304 7.29301C7.68403 6.90201 8.3161 6.90201 8.7071 7.29301C9.0981 7.68401 9.0981 8.31607 8.7071 8.70707L6.41413 11H20.0001C20.5531 11 21.0001 11.448 21.0001 12Z" fill="#745E50"/>
          </svg>
        </button>
        <h1 className="title">Earn More</h1>
      </div>
      <div className="tasks-content">
        <div className="image-container">
          <img src="images/earn_more.png" alt="Earn More" className="cute-characters" />
        </div>
        <p className="instruction-text">Complete the following tasks and grab more coins</p>
        <div className="tasks-list">
          <h2>Tasks List</h2>
          {tasks.map((task, index) => (
            <div key={index} className="task-item">
              <div className="task-info">
                <span className="task-icon"><img src={`images/tasks/${task.icon}.svg`} alt="Earn More"/></span>
                
              </div>
              <div className='task-content'>
              <span className="task-title">{task.title}</span>
              <span className="task-coins"><img src='images/coin.png' height={12} width={12} /> {task.coins}</span>
              </div>
              <div className="task-reward">
               
                <button className="claim-button" onClick={()=>{taskOnClick(task.key)}}>Claim</button>
              </div>
            </div>
          ))}

        </div>
      </div>
      {(dailyReward ) && (
        <DailyReward onCancel={toggleDailyReward}
        />
      )}
    </div>
  );
};

export default Tasks;