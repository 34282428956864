// components/ConfirmationDialog.js
import React from 'react';
import './ActionDialog.css';
import utilities from '../../services/utilities.js';

const ActionDialog = ({ title, message, card, onConfirm, onCancel }) => {
  return (
    <div className="overlay">
     <div className="food-item">
      <div className="close-button" onClick={onCancel}>×</div>
      <h3>{card.name}</h3>
      <div className="card-icon"> 
      <img src={`images/cards/${card.image}.png`} alt={card.name} width={100} height={100} />
      </div>
      <p>{card.description}</p>
      <div className="ratings">
      <img src={`images/coin.png`} alt={card.name} width={12} height={12} /> 
      <span>+{Math.round( card.ratePerHour * (card.rateMultiplier-1) )}/hr</span>
      </div>
      <div className="price">
      <img src={`images/coin.png`} alt={card.name} width={12} height={12} />  
      <span>{card.levelUpCost.toLocaleString()}</span>
      </div>
      <button className="buy-button" onClick={onConfirm}>Buy</button>
    </div>
      {/* <div className="confirmation">
        <h3>{title}</h3>
        <p>{message}</p>
        <button className="confirm" onClick={onConfirm}>Confirm</button>
        <button className="cancel" onClick={onCancel}>Cancel</button>
      </div> */}
    </div>
  );
};

export default ActionDialog;
