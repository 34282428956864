import React from 'react';
import './Airdrop.css';

const Airdrop = () => {
  const roadmapItems = [
    { text: "Development of Fufu's kitchen", status: 'completed' },
    { text: "Game launch", status: 'current' },
    { text: "Establishment of Social accounts&Community", status: 'upcoming' },
    { text: "Hourly earnings", status: 'upcoming' },
    { text: "Partnership", status: 'upcoming' },
    { text: "Arcade Launch", status: 'upcoming' },
    { text: "Reward Calculations", status: 'upcoming' },
    { text: "Wallet Sumission", status: 'upcoming' },
    { text: "Listing", status: 'upcoming' },
    { text: "Token Airdrop", status: 'upcoming' },
  ];

  const currentIndex = roadmapItems.findIndex(item => item.status === 'current');

  return (
    <div className="airdrop">
      <div className="roadmap-container">
        <h2>Roadmap</h2>
        <ul className="roadmap">
          {roadmapItems.map((item, index) => {
            let opacity = 1;
            if (index > currentIndex) {
              opacity = 0.9 - ((index - currentIndex - 1) * 0.1);
              opacity = Math.min(opacity, 1); // Ensure opacity doesn't exceed 1
            }
            return (
              <li 
                key={index} 
                className={item.status}
                style={{
                  '--opacity': opacity,
                  '--circle-opacity': opacity
                }}
              >
                {item.text}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="mascot">
        <img src="images/airdrop2.png" alt="Cute mascot with burger" width={250} height={125} />
      </div>
     
    </div>
  );
};

export default Airdrop;