import React, { useState, useEffect } from 'react';
import apiService from '../../services/apiService';
import utilities from '../../services/utilities';
import './UserFriend.css';

const UserFriend = () => {
  const [friends, setFriends] = useState([]);
  const [urlLink, setUrlLink] = useState('');

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await apiService.get('/friend');
        const friendData = response.data.data || [];
        console.log(friendData);
        setFriends(Array.isArray(friendData) ? friendData : []);
      } catch (error) {
        console.error('Error fetching mining cards:', error);
      }
    };

    fetchCards();
  }, []);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
      let telegrmaID = window.Telegram.WebApp.initDataUnsafe.user.id;
      console.log(telegrmaID);
      setUrlLink(`https://t.me/sbt_first_game_bot/taptowin?startapp=refid`);
      if (telegrmaID) {
        setUrlLink(`https://t.me/sbt_first_game_bot/taptowin?startapp=refid${telegrmaID}`);
      }
    }
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(urlLink).then(() => {
      
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  };

  return (
    <div className="user-friend-container">
    <div className="header">
      <button className="back-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.0001 12C21.0001 12.552 20.5531 13 20.0001 13H6.41413L8.7071 15.293C9.0981 15.684 9.0981 16.3161 8.7071 16.7071C8.5121 16.9021 8.25607 17 8.00007 17C7.74407 17 7.48804 16.9021 7.29304 16.7071L3.29401 12.708C3.20101 12.615 3.12797 12.5051 3.07697 12.3821C2.97597 12.1381 2.97597 11.862 3.07697 11.618C3.12797 11.495 3.20101 11.385 3.29401 11.292L7.29304 7.29301C7.68403 6.90201 8.3161 6.90201 8.7071 7.29301C9.0981 7.68401 9.0981 8.31607 8.7071 8.70707L6.41413 11H20.0001C20.5531 11 21.0001 11.448 21.0001 12Z" fill="#745E50"/>
        </svg>
      </button>
      <h1 className="title">Invite Your Buddies</h1>
    </div>

    <div className="user-friend-content">
      <div className="image-container">
        <img src="images/buddy.png" alt="buddy" className="friend-cute-character" />
      </div>

      <p className="reward-text">You and your buddies will get 100,000 tokens each.</p>
      <p className="bonus-text">+ you'll get 10% of their earnings</p>

      <div className="friends-container">
        <h2>{friends.length} Buddies</h2>
        <ul className="friend-list">
          {friends.map((friend, index) => (
            <li key={index} className="friend-item">
              <div className="friend-avatar"></div>
              <span className="friend-name">{friend.first_name}</span>
              <span className="friend-tokens"><img src='images/coin.png' height={12} width={12} />{utilities.formatNumber(friend.balance||0)}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="invite-container">
  <a className="invite-button" href={`https://t.me/share/url?url=`+encodeURIComponent(urlLink)}>
    Invite a Buddy
  </a>
  <button className="copy-button" onClick={copyToClipboard}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z" fill="currentColor"/>
    </svg>
  </button>
</div>

    </div>
  </div>
  );
};

export default UserFriend;
